import {
    useTableFeatures,
    TableColumnId,
    TableColumnDefinition,
    useTableSelection,
    makeStyles,
    Option, 
    Table, 
    TableBody, 
    TableCell, 
    TableCellLayout, 
    TableHeader, 
    TableHeaderCell, 
    TableRow, 
    TableSelectionCell, 
    Input, 
    Combobox, 
    Toolbar, 
    ToolbarButton, 
    ToolbarDivider, 
    Tooltip, 
    TableRowId, 
    Field, 
    Button, 
    Radio, 
    RadioGroup, 
    useId, 
    Label, 
    typographyStyles, 
    ToggleButton,
    Spinner
} from "@fluentui/react-components";

import React, { useState, useEffect, useRef } from "react";

import hash from 'object-hash'
import EmailCombobox from "./EmailCombobox";
import { ArrowReset24Regular, ArrowSync24Regular, Checkmark24Regular, Delete24Regular, FormNew24Regular, History24Regular, Save24Regular, Send24Regular } from "@fluentui/react-icons";
import axios from "axios";
import { DefaultApproverDataItem, Person, ApproverDataItem, UniqueApproverDataItem, ApproverCell, ApproverItem, ApproverType, DisplayMode, PouchDBMetaID, RequestStatus, SystemAccessRequest } from "../interfaces";
import { v4 } from "uuid";


declare interface CreateTableExtendedColumnOptions<TItem> extends TableColumnDefinition<TItem> {
    columnId: TableColumnId;
    displayMode: DisplayMode;
}

function createExtendedTableColumn<TItem>(options: CreateTableExtendedColumnOptions<TItem>) {
    return options
};

const buildServerDescription = (item: ApproverItem) => {
    return item.server.name
}

const validateEmail = (email: any) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const useStyles = makeStyles({
    tableTitle: typographyStyles.title3
})

const columns = [
    createExtendedTableColumn<ApproverItem>({
        displayMode: DisplayMode.Both,
        columnId: "sid",
        compare: (a, b) => {
            return a.server.sid.localeCompare(b.server.sid);
        },
        renderCell: (approverItem) => <></>,
        renderHeaderCell: () => <>SID</>,
    }),
    /**
     * EDIT SERVER DESCRIPTION
     */
    createExtendedTableColumn<ApproverItem>({
        displayMode: DisplayMode.Edit,
        columnId: "name",
        compare: (a, b) => {
            return a.server.name.localeCompare(b.server.name);
        },
        renderCell: (approverItem) => <></>,
        renderHeaderCell: () => <>Server</>,
    }),
    createExtendedTableColumn<ApproverItem>({
        displayMode: DisplayMode.Edit,
        columnId: "type",
        compare: (a, b) => {
            return a.server.type.localeCompare(b.server.type);
        },
        renderCell: (approverItem) => <></>,
        renderHeaderCell: () => <>Type</>,
    }),
    createExtendedTableColumn<ApproverItem>({
        displayMode: DisplayMode.Edit,
        columnId: "version",
        compare: (a, b) => {
            return a.server.version.localeCompare(b.server.version);
        },
        renderCell: (approverItem) => <></>,
        renderHeaderCell: () => <>Version</>,
    }),
    /**
     * VIEW SERVER DESCRIPTION
     */
    createExtendedTableColumn<ApproverItem>({
        displayMode: DisplayMode.View,
        columnId: "server",
        compare: (a, b) => {
            return buildServerDescription(a).localeCompare(buildServerDescription(b));
        },
        renderCell: (approverItem) => <></>,
        renderHeaderCell: () => <>Description</>,
    }),
    /**
     * CLIENT
     */
    createExtendedTableColumn<ApproverItem>({
        displayMode: DisplayMode.Both,
        columnId: "clientId",
        compare: (a, b) => {
            return a.server.client.id - b.server.client.id;
        },
        renderCell: (approverItem) => <></>,
        renderHeaderCell: () => <>Client</>,
    }),
    createExtendedTableColumn<ApproverItem>({
        displayMode: DisplayMode.Edit,
        columnId: "clientDescription",
        compare: (a, b) => {
            return a.server.client.description.localeCompare(b.server.client.description);
        },
        renderCell: (approverItem) => <></>,
        renderHeaderCell: () => <>Client Description</>,
    }),
    /**
     * APPROVER
     */
    createExtendedTableColumn<ApproverItem>({
        displayMode: DisplayMode.Both,
        columnId: "functionalApprover",
        compare: (a, b) => {
            const aApproverName = a.server.approvers.get(ApproverType.Functional)?.approverName;
            const bApproverName = b.server.approvers.get(ApproverType.Functional)?.approverName;
            if (aApproverName === undefined && bApproverName === undefined) return 0;
            if (aApproverName === undefined) return -1;
            if (bApproverName === undefined) return 1;
            return aApproverName.localeCompare(bApproverName);
        },
        renderCell: (approverItem) => <></>,
        renderHeaderCell: () => <>Functional Approver</>,
    }),
    createExtendedTableColumn<ApproverItem>({
        displayMode: DisplayMode.Both,
        columnId: "technicalApprover",
        compare: (a, b) => {
            const aApproverName = a.server.approvers.get(ApproverType.Technical)?.approverName;
            const bApproverName = b.server.approvers.get(ApproverType.Technical)?.approverName;
            if (aApproverName === undefined && bApproverName === undefined) return 0;
            if (aApproverName === undefined) return -1;
            if (bApproverName === undefined) return 1;
            return aApproverName.localeCompare(bApproverName);
        },
        renderCell: (approverItem) => <></>,
        renderHeaderCell: () => <>Technical Approver</>,
    }),
    createExtendedTableColumn<ApproverItem>({
        displayMode: DisplayMode.Both,
        columnId: "basisApprover",
        compare: (a, b) => {
            const aApproverName = a.server.approvers.get(ApproverType.Basis)?.approverName;
            const bApproverName = b.server.approvers.get(ApproverType.Basis)?.approverName;
            if (aApproverName === undefined && bApproverName === undefined) return 0;
            if (aApproverName === undefined) return -1;
            if (bApproverName === undefined) return 1;
            return aApproverName.localeCompare(bApproverName);
        },
        renderCell: (approverItem) => <></>,
        renderHeaderCell: () => <>Basis Approver</>,
    }),
    /**
     * REF USER EDIT
     */
    createExtendedTableColumn<ApproverItem>({
        displayMode: DisplayMode.Edit,
        columnId: "basisRefUser",
        compare: (a, b) => {
            const aApproverName = a.server.approvers.get(ApproverType.Basis)?.referenceUser;
            const bApproverName = b.server.approvers.get(ApproverType.Basis)?.referenceUser;
            if (aApproverName === undefined && bApproverName === undefined) return 0;
            if (aApproverName === undefined) return -1;
            if (bApproverName === undefined) return 1;
            return aApproverName.localeCompare(bApproverName);
        },
        renderCell: (approverItem) => <></>,
        renderHeaderCell: () => <>Basis Ref User</>,
    }),
    createExtendedTableColumn<ApproverItem>({
        displayMode: DisplayMode.Edit,
        columnId: "functionalRefUser",
        compare: (a, b) => {
            const aApproverName = a.server.approvers.get(ApproverType.Functional)?.referenceUser;
            const bApproverName = b.server.approvers.get(ApproverType.Functional)?.referenceUser;
            if (aApproverName === undefined && bApproverName === undefined) return 0;
            if (aApproverName === undefined) return -1;
            if (bApproverName === undefined) return 1;
            return aApproverName.localeCompare(bApproverName);
        },
        renderCell: (approverItem) => <></>,
        renderHeaderCell: () => <>Functional Ref User</>,
    }),
    createExtendedTableColumn<ApproverItem>({
        displayMode: DisplayMode.Edit,
        columnId: "technicalRefUser",
        compare: (a, b) => {
            const aApproverName = a.server.approvers.get(ApproverType.Technical)?.referenceUser;
            const bApproverName = b.server.approvers.get(ApproverType.Technical)?.referenceUser;
            if (aApproverName === undefined && bApproverName === undefined) return 0;
            if (aApproverName === undefined) return -1;
            if (bApproverName === undefined) return 1;
            return aApproverName.localeCompare(bApproverName);
        },
        renderCell: (approverItem) => <></>,
        renderHeaderCell: () => <>Technical Ref User</>,
    }),

    /**
     * EDIT PORT
     */
    createExtendedTableColumn<ApproverItem>({
        displayMode: DisplayMode.Edit,
        columnId: "port",
        compare: (a, b) => {
            return a.server.port - b.server.port;
        },
        renderCell: (approverItem) => <></>,
        renderHeaderCell: () => <>Port</>,
    }),

    /**
     * EDIT ADDRESS
     */
    createExtendedTableColumn<ApproverItem>({
        displayMode: DisplayMode.Edit,
        columnId: "address",
        compare: (a, b) => {
            return a.server.address.localeCompare(b.server.address);
        },
        renderCell: (approverItem) => <></>,
        renderHeaderCell: () => <>Address</>,
    }),
];

const saveTableChanges = async (propsData: any) => {
    try {
        await axios.post('/systemsList', {
            systemsList: propsData
        })
    } catch (error) {
        console.error(error);
    }

}

const mapApproverDataToApproverItemArray = (propsData: Array<UniqueApproverDataItem>): Array<ApproverItem> => {
    return propsData.map((adi: UniqueApproverDataItem) => {
        const server = {
            sid: adi.SID,
            name: adi.Name,
            version: adi.Version,
            type: adi.Type,
            client: {
                id: parseInt(adi.Client),
                description: adi["Client Description"]
            },
            address: adi["ASCS Server"],
            port: parseInt(adi.Port),
            instanceNumber: adi["Instance Number"],
            approvers: new Map<ApproverType, ApproverCell<ApproverType>>([
                [
                    ApproverType.Functional,
                    {
                        approverType: ApproverType.Functional,
                        referenceUser: adi["Functional Ref User"],
                        approverEmail: adi["Functional Approver Email"],
                        approverName: adi["Functional Approver Name"]
                    }
                ],
                [
                    ApproverType.Basis,
                    {
                        approverType: ApproverType.Basis,
                        referenceUser: adi["Basis Ref User"],
                        approverEmail: adi["Basis Approver Email"],
                        approverName: adi["Basis Approver Name"]
                    }
                ],
                [
                    ApproverType.Technical,
                    {
                        approverType: ApproverType.Technical,
                        referenceUser: adi["Technical Ref User"],
                        approverEmail: adi["Technical (ABAP) Approver Email"],
                        approverName: adi["Technical (ABAP) Approver Name"]
                    }
                ]
            ]),
        }
        return {
            adiUUID: adi.UUID,
            hash: adi.hash !== undefined ? adi.hash : hash(server),
            currentHash: hash(server),
            server
        } as ApproverItem
    })
}

const filterServer = (propsData: any, selectedServerFilter: Array<any>): Array<any> => {
    if (selectedServerFilter.length === 0) return propsData;
    return propsData.filter((item: ApproverItem) => {
        return selectedServerFilter.some((selectedServer: string) => selectedServer === item.server.sid)
    })
}

const buildFilteredApproverItemArray = (approverData: Array<UniqueApproverDataItem>, selectedServerFilter: Array<any>): Array<any> => {
    return filterServer(mapApproverDataToApproverItemArray(approverData), selectedServerFilter)
}

const computeUniqueSystems = (systemsList: Array<ApproverDataItem>) => {
    return [...(new Set(...[systemsList.map(prop => prop.SID)])).values()]
}

const sendSystemAccessRequest = async (rows: Array<any>, selectedRows: Set<any>, requests: Array<SystemAccessRequest>, requestedBy: string, requestedFor: string, approverType: string, token: any, sendAlert: Function) => {

    return await Promise.allSettled(rows.map((row, index) => {
        if (selectedRows.has(index)) {
            if (!(requests as Array<SystemAccessRequest>).some(request => {
                return request.status === RequestStatus.REQUESTED && 
                    request.requester === requestedFor &&
                    row.item.server.sid === request.sid && 
                    row.item.server.client.id === request.client.id && 
                    row.item.server.approvers.get(approverTypeToEnum(approverType))?.referenceUser === request.refid
            })) {
                const approver = row.item.server.approvers.get(approverTypeToEnum(approverType))
                const data = { ...row.item.server, requestedBy, requester: requestedFor, approverType: approverType[0].toUpperCase() + approverType.slice(1), approver: approver?.approverEmail, refid: approver?.referenceUser }
                delete data.approvers
                return axios.post("/requestSystemAccess", data, { headers: { Authorization: 'Bearer ' + token } })
            } else {
                sendAlert({ message: row.item.server.sid + ' - ' + row.item.server.client.id + ' - ' + approverType + ' is already requested.', intent: "warning", id: v4() })
            }
        }
        return undefined;
    }).filter(row => row !== undefined))

}

const approverTypeToEnum = (approverType: string): ApproverType => {
    switch (approverType) {
        case 'functional':
            return ApproverType.Functional;
        case 'basis':
            return ApproverType.Basis;
        case 'technical':
            return ApproverType.Technical;
        default:
            return ApproverType.Technical;
    }
}

const setApproverItemData = (approverItemData: Array<UniqueApproverDataItem>, item: ApproverItem, field: string, data: any) => {
    let approverDataItemIndex = approverItemData.findIndex((adi: UniqueApproverDataItem) => adi.UUID === item.adiUUID);
    let approverDataItem = approverItemData[approverDataItemIndex];
    switch (field) {
        case 'functionalApprover':
            approverDataItem["Functional Approver Name"] = (data as Person).name
            approverDataItem["Functional Approver Email"] = (data as Person).email
            break;
        case 'functionalRefUser':
            approverDataItem["Functional Ref User"] = data
            break;
        case 'technicalApprover':
            approverDataItem["Technical (ABAP) Approver Name"] = (data as Person).name
            approverDataItem["Technical (ABAP) Approver Email"] = (data as Person).email
            break;
        case 'technicalRefUser':
            approverDataItem["Technical Ref User"] = data
            break;
        case 'basisApprover':
            approverDataItem["Basis Approver Name"] = (data as Person).name
            approverDataItem["Basis Approver Email"] = (data as Person).email
            break;
        case 'basisRefUser':
            approverDataItem["Basis Ref User"] = data
            break;
        case 'sid':
            approverDataItem["SID"] = data
            break;
        case 'name':
            approverDataItem.Name = data
            break;
        case 'version':
            approverDataItem.Version = data
            break;
        case 'type':
            approverDataItem.Type = data
            break;
        case 'port':
            approverDataItem.Port = data
            break;
        case 'address':
            approverDataItem["ASCS Server"] = data
            break;
        case 'clientDescription':
            approverDataItem["Client Description"] = data;
            break;
        case 'clientId':
            approverDataItem["Client"] = data
            break;
    }
    approverItemData[approverDataItemIndex] = approverDataItem;
    return approverItemData;
}

const makeArrayDataUnique = (arrayData: Array<any>): Array<any & 'UUID'> => {
    return arrayData.map((entry) => ({ ...entry, UUID: v4(), hash: undefined }))
}

const SystemsTable = (props: any) => {

    const styles = useStyles()

    const [loadingRequestHistory, setLoadingRequestHistory] = useState<boolean>(false)

    const [requestedBy, _setRequestedBy] = useState<string>(props.graphData.data !== undefined ? props.graphData.data.userInfo.preferredUserName : '');
    const [requestedFor, setRequestedFor] = useState<string>(props.graphData.data !== undefined ? props.graphData.data.userInfo.preferredUserName : '');
    const requesterInputId = useId("requesterInput")

    const approverTypeId = useId("approverTypeRadioGroupLabel");
    const [approverType, setApproverType] = useState<string>("functional")

    const [approverData, setApproverData] = useState<Array<UniqueApproverDataItem>>([]);
    const [approverItems, setApproverItems] = useState<Array<ApproverItem>>([]);
    const [showHistory, setShowHistory] = useState<boolean>(false);

    const tableBody = useRef<any>()

    // const [approverEditData, setApproverEditData] = useState<Array<UniqueApproverDataItem>>([]);
    // const [approverEditItems, setApproverEditItems] = useState<Array<ApproverItem>>([])

    const [hasChanged, setHasChanged] = useState<boolean>(false)

    const [selectedRows, setSelectedRows] = React.useState(
        () => new Set<TableRowId>([])
    );

    const [selectedServerFilter, setSelectedServerFilter] = useState<Array<string>>([]);
    const [computedSystems, setComputedSystems] = useState<Array<string>>([]);

    const discardTableChanges = () => {
        setSelectedRows(new Set())
    }

    const addNewDefaultRow = () => {
        if (props.editMode) {
            const newDefaultRow = { ...JSON.parse(JSON.stringify(DefaultApproverDataItem)), "Name": "Server-" + v4().split("-")[0], UUID: v4() };
            setApproverData([...approverData, newDefaultRow] as Array<UniqueApproverDataItem>)
            props.sendAlert({ message: "Added a new row to the Table", intent: "info", id: v4() })
        } else {
            props.sendAlert({ message: "Cannot a new row to the Table", intent: "error", id: v4() })
        }
    }

    useEffect(() => {
        setApproverItems(buildFilteredApproverItemArray([...approverData], [...selectedServerFilter]))
    }, [selectedServerFilter])

    useEffect(() => {
        setApproverItems(buildFilteredApproverItemArray([...approverData], [...selectedServerFilter]))
        setComputedSystems(computeUniqueSystems([...approverData]))
    }, [approverData, props.editMode])

    useEffect(() => {
        discardTableChanges();
        const uniqueSystemsList = makeArrayDataUnique(props.data) as Array<UniqueApproverDataItem>
        setApproverData(uniqueSystemsList)
        const items = buildFilteredApproverItemArray([...uniqueSystemsList], [...selectedServerFilter])
        setApproverItems(items)
        setComputedSystems(computeUniqueSystems([...uniqueSystemsList]))
    }, [])

    const {
        getRows,
        selection: {
            allRowsSelected,
            someRowsSelected,
            deselectRow,
            toggleAllRows,
            toggleRow,
            isRowSelected,
        },
    } = useTableFeatures(
        {
            columns,
            items: approverItems,
        },
        [
            useTableSelection({
                selectionMode: "multiselect",
                defaultSelectedItems: new Set([]),
                selectedItems: selectedRows,
                onSelectionChange: (e, data) => setSelectedRows(data.selectedItems),
            }),
        ]
    );

    const rows = getRows((row) => {
        const selected = isRowSelected(row.rowId);
        // let hasRowChanged = false
        // if (props.editMode) {
        //     hasRowChanged = isRowChanged(row.item, approverEditItems.find(item => item.adiUUID === row.item.adiUUID)!)
        // }
        return {
            ...row,
            onClick: (e: React.MouseEvent) => { 
                toggleRow(e, row.rowId)
            },
            onKeyDown: (e: React.KeyboardEvent) => {
                if (e.key === " ") {
                    e.preventDefault();
                    toggleRow(e, row.rowId)
                }
            },
            selected,
            appearance: selected ? ("brand" as const) : ("none" as const),
            // hasRowChanged
        };
    })

    // if (props.editMode)
    //     setHasChanged(rows.some(row => row.hasRowChanged))

    const toggleAllKeydown = React.useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === " ") {
                toggleAllRows(e);
                e.preventDefault();
            }
        },
        [toggleAllRows]
    );
    return (
        <div style={{ width: '100%', height: '100%', margin: 'auto', marginTop: '1em' }}>
            {/* TOOLBAR */}
            <Toolbar aria-label="Default" {...props} style={{ marginBottom: '2em' }}>
                {props.editMode ?
                    (
                        <>
                            <Tooltip
                                content="Adds a new line to the Table"
                                relationship="label"
                                withArrow
                            >
                                <ToolbarButton
                                    onClick={() => addNewDefaultRow()}
                                    icon={<FormNew24Regular />}
                                />
                            </Tooltip>
                            <Tooltip
                                content="Saves the changes made to the table"
                                relationship="label"
                                withArrow
                            >
                                <ToolbarButton
                                    disabled={!hasChanged}
                                    onClick={() => {
                                        saveTableChanges(approverData);
                                        props.sendAlert({ message: "Saved Changes", intent: "success", id: v4() })
                                    }}
                                    icon={<Save24Regular />}
                                />
                            </Tooltip>
                            <Tooltip
                                content="Delete selected rows"
                                relationship="label"
                                withArrow
                            >
                                <ToolbarButton
                                    disabled={selectedRows.size === 0}
                                    onClick={() => {
                                        // const numberOfRowsDeleted = deleteSelectedRows()
                                        // props.sendAlert({ message: "Deleted " + numberOfRowsDeleted + ' Rows', intent: "warning" })
                                    }}
                                    icon={<Delete24Regular />}
                                />
                            </Tooltip>
                            <Tooltip
                                content="Discards the changes made to the table"
                                relationship="label"
                                withArrow
                            >
                                <ToolbarButton
                                    disabled={!hasChanged}
                                    onClick={() => {
                                        discardTableChanges()
                                        props.sendAlert({ message: "Discarded Changes", intent: "warning", id: v4() })
                                    }}
                                    icon={<ArrowReset24Regular />}
                                />
                            </Tooltip>
                            <Tooltip
                                content="Reload Table Data"
                                relationship="label"
                                withArrow
                            >
                                <ToolbarButton
                                    onClick={() => {
                                        props.fetch()
                                        props.sendAlert({ message: "Fetching Latest Data", intent: "info", id: v4() })
                                    }}
                                    icon={<ArrowSync24Regular />}
                                />
                            </Tooltip>
                            <Tooltip
                                content="Print Table Body Data"
                                relationship="label"
                                withArrow
                            >
                                <ToolbarButton
                                    onClick={() => {
                                        let data = [] as any[]
                                        tableBody.current.children.forEach((row: any, rowIndex: number) => {
                                            if (!Array.isArray(data[rowIndex])) data[rowIndex] = []
                                            row.children.slice(1).forEach((field: any, index: number) => {
                                                field.children.forEach((child: any) => {
                                                    while (child.children.length > 0) {
                                                        if (!child.children.some((baby: any) => baby instanceof HTMLInputElement)) {
                                                            child = child.children
                                                        } else {
                                                            let input = child.children.find((baby: any) => baby instanceof HTMLInputElement)
                                                            data[rowIndex][index] = (input as HTMLInputElement).value
                                                        }
                                                    }
                                                })

                                            })
                                        })
                                    }}
                                    icon={<ArrowSync24Regular />}
                                />
                            </Tooltip>
                        </>

                    ) :
                    (
                        <>
                            <Tooltip
                                content={ ( showHistory ? 'Hide' : 'Show' )+ ' Request History'}
                                relationship="label"
                                withArrow
                            >
                                <ToggleButton
                                    appearance="subtle"
                                    onClick={() => { setShowHistory(showHistory => !showHistory) }}
                                    icon={<History24Regular />}
                                ></ToggleButton>
                            </Tooltip>
                            <Tooltip
                                content="Request access to selected systems"
                                relationship="label"
                                withArrow
                            >
                                <Button
                                    appearance="primary"
                                    disabled={selectedRows.size === 0}
                                    onClick={async () => {
                                        try {

                                            if (!validateEmail(requestedFor)) {
                                                props.sendAlert({ message: "Please enter a valid Requester Email Address", intent: "error", id: v4() })
                                                return;
                                            }

                                            const promises = await sendSystemAccessRequest(rows, selectedRows, props.requests, requestedBy, requestedFor, approverType, props.graphData.data?.userToken.token, props.sendAlert)
                                            
                                            const successfulPromises = promises.filter(promise => promise.status === "fulfilled")
                                            
                                            if (successfulPromises.length > 0) {

                                                let alreadyRequested = successfulPromises.filter(sp => sp.status === 'fulfilled' && sp.value?.data.status === 'already requested')
                                                let sent = successfulPromises.filter(sp => sp.status === 'fulfilled' && sp.value?.data.status === 'requested')

                                                if (alreadyRequested.length > 0)
                                                    props.sendAlert({ message: "Ignoring " + alreadyRequested.length + " System Access Requests which are already requested.", intent: 'warning', id: v4() })
                                                if (sent.length > 0)
                                                    props.sendAlert({ message: "Sent " + sent.length + " System Access Requests", intent: "success", id: v4() })
                                            }
                                            
                                            const erroredPromises = promises.filter(promise => promise.status === "rejected")
                                            
                                            if (erroredPromises.length > 0) {
                                                props.sendAlert({ message: "Failed to send " + erroredPromises.length + " System Access Requests.", intent: "error", id: v4() })
                                            }

                                            await props.fetchRequests(props.graphData.data.userToken)
                                        } catch (error) {
                                            props.sendAlert({ message: "Failed to request System Access", intent: "error", id: v4() })
                                        }
                                    }}
                                    icon={<Send24Regular />}
                                >
                                    Request System Access
                                </Button>
                            </Tooltip>
                        </>


                    )
                }
                <ToolbarDivider />
                <Combobox
                    multiselect={true}
                    placeholder="Server Filter"
                    value={selectedServerFilter.join(', ')}
                    onOptionSelect={(ev, data) => {
                        setSelectedServerFilter(data.selectedOptions)
                        setSelectedRows(new Set([]))
                    }}
                >
                    {computedSystems.map(option => {
                        return (
                            <Option key={option} value={option} checkIcon={selectedServerFilter.includes(option) ? <Checkmark24Regular /> : null} >{option}</Option>
                        )
                    })}
                    {computedSystems.length === 0 ? <Option key="no-results" text="">No Results Found</Option> : null}
                </Combobox>
                {
                    !props.editMode ?
                        <>
                            <ToolbarDivider />
                            <div>
                                <Label id={approverTypeId}>Type of Access</Label>
                                <RadioGroup
                                    value={approverType}
                                    onChange={(_, data) => setApproverType(data.value)}
                                    layout="horizontal" aria-labelledby={approverTypeId}
                                >
                                    <Radio value="technical" label="Technical" />
                                    <Radio value="functional" label="Functional" />
                                    <Radio value="basis" label="Basis" />
                                </RadioGroup>
                            </div>
                        </> : null
                }
                {
                    !props.editMode ?
                        <>
                            <ToolbarDivider />
                            <div style={{ width: "300px" }}>
                                <Field
                                    label="Requester" required
                                    validationMessage={!validateEmail(requestedFor) ? 'Please Enter a Valid Email Address' : ''}
                                    validationState={!validateEmail(requestedFor) ? 'error' : 'none'}
                                >
                                    <Input
                                        placeholder="Requested For Email Address"
                                        type={'text'}
                                        defaultValue={requestedFor}
                                        onChange={(_, data) => {
                                            setRequestedFor(data.value)
                                        }}
                                        id={requesterInputId}
                                    ></Input>
                                </Field>

                            </div>
                        </> : null
                }
            </Toolbar>

            {
                showHistory ?
                    (<>
                        {/* CURRENT REQUESTS TABLE */}
                        <span className={styles.tableTitle}> Request History <Button icon={<ArrowSync24Regular/>} appearance={'primary'} onClick={async () => {
                            setLoadingRequestHistory(true)
                            await props.fetchRequests(props.graphData.data.userToken)
                            setLoadingRequestHistory(false)
                        }}></Button></span>
                        { !loadingRequestHistory ? (
                            <Table
                                noNativeElements
                                aria-rowcount={props.requests.length}
                                style={{ overflowY: 'auto', overflowX: 'auto' }}
                            >
                                <TableHeader>
                                    <TableRow aria-rowindex={1}>
                                        <TableHeaderCell key={'requests-column-header-sid'}>
                                            <>SID</>
                                        </TableHeaderCell>
                                        <TableHeaderCell key={'requests-column-header-client'}>
                                            <>Client</>
                                        </TableHeaderCell>
                                        <TableHeaderCell key={'requests-column-header-refid'}>
                                            <>Reference ID</>
                                        </TableHeaderCell>
                                        <TableHeaderCell key={'requests-column-header-status'}>
                                            <>Status</>
                                        </TableHeaderCell>
                                        <TableHeaderCell key={'requests-column-header-comment'}>
                                            <>Comment</>
                                        </TableHeaderCell>
                                        <TableHeaderCell key={'requests-column-header-approver'}>
                                            <>Approver</>
                                        </TableHeaderCell>
                                        <TableHeaderCell key={'requests-column-header-timestamp'}>
                                            <>Timestamp</>
                                        </TableHeaderCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {props.requests.filter((request: SystemAccessRequest & PouchDBMetaID) => {
                                        if (selectedServerFilter.length === 0) return true;
                                        return selectedServerFilter.some((selectedServer: string) => selectedServer === request.sid)
                                    }).map((request: SystemAccessRequest & PouchDBMetaID) => {
                                        return (
                                            <TableRow key={request._id}>
                                                <TableCell>
                                                    <TableCellLayout>
                                                        {request.sid}
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell>
                                                    <TableCellLayout>
                                                        {request.client.id}
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell>
                                                    <TableCellLayout>
                                                        {request.refid}
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell>
                                                    <TableCellLayout>
                                                        {RequestStatus[request.status]}
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell>
                                                    <TableCellLayout>
                                                        {request.comment ? request.comment : ''}
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell>
                                                    <TableCellLayout>
                                                        {request.approver}
                                                    </TableCellLayout>
                                                </TableCell>
                                                <TableCell>
                                                    <TableCellLayout>
                                                        {request.timestamp ? (new Date(request.timestamp.toString()).toLocaleString()) : ''}
                                                    </TableCellLayout>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        ) : <Spinner/>}
                        
                    </>) :
                    (<>
                        {/* REQUEST NEW TABLE */}
                        <span className={styles.tableTitle}> New System Access Request </span>
                        <Table
                            noNativeElements
                            aria-label="Table with selection"
                            aria-rowcount={rows.length}
                            style={{ overflowY: 'auto', overflowX: 'auto' }}
                        >
                            <TableHeader>
                                <TableRow aria-rowindex={1}>
                                    <TableSelectionCell
                                        checked={
                                            allRowsSelected ? true : someRowsSelected ? "mixed" : false
                                        }
                                        onClick={toggleAllRows}
                                        onKeyDown={toggleAllKeydown}
                                        checkboxIndicator={{ "aria-label": "Select all rows " }}
                                    />
                                    {columns.filter(column => {
                                        if (column.displayMode === DisplayMode.Both) {
                                            if (!props.editMode && (column.columnId === 'functionalApprover' || column.columnId === 'technicalApprover' || column.columnId === 'basisApprover')) {
                                                return column.columnId.toString().startsWith(approverType)
                                            }
                                            return true;
                                        }

                                        return props.editMode ? column.displayMode !== DisplayMode.View : column.displayMode !== DisplayMode.Edit
                                    }).map(column => (
                                        <TableHeaderCell
                                            key={column.columnId}
                                        // {...columnSizing.getTableHeaderCellProps(column.columnId)}
                                        // {...headerSortProps(column.columnId)}
                                        >
                                            {column.renderHeaderCell()}
                                        </TableHeaderCell>
                                    ))}
                                </TableRow>
                            </TableHeader>
                            <TableBody ref={tableBody}>
                                {rows.map(({ item, selected, appearance, onClick, onKeyDown }, index) => {
                                    return (
                                        <TableRow
                                            key={item.currentHash + '-' + index}
                                            aria-selected={selected}
                                            appearance={appearance}
                                            style={{ backgroundColor: hasChanged ? '#ffffe0' : 'inherit' }}
                                        >
                                            <TableSelectionCell
                                                onClick={onClick}
                                                onKeyDown={onKeyDown}
                                                subtle={true}
                                                checked={selected}
                                                checkboxIndicator={{ "aria-label": "Select row" }}
                                            />
                                            <TableCell
                                            // {...columnSizing.getTableCellProps('sid')} key={'sid'}
                                            >
                                                {props.editMode ? (<TableCellLayout>
                                                    <Input
                                                        key={item.adiUUID + '-sid'}
                                                        appearance="underline"
                                                        type="text"
                                                        defaultValue={item.server.sid}
                                                    // value={item.server.sid}
                                                    // onChange={(ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                                    //     setApproverEditData(setApproverItemData([...approverEditData], item, 'sid', data.value))
                                                    // }}
                                                    />
                                                </TableCellLayout>) : (
                                                    <TableCellLayout>
                                                        {item.server.sid}
                                                    </TableCellLayout>
                                                )}
                                            </TableCell>

                                            {props.editMode ? (
                                                <>
                                                    <TableCell
                                                        key={'table-cell-name'}
                                                    >
                                                        <TableCellLayout key={'table-cell-layout-name'}>
                                                            <Input
                                                                key={item.adiUUID + '-name'}
                                                                appearance="underline"
                                                                type="text"
                                                                defaultValue={item.server.name}
                                                            // value={item.server.name}
                                                            // onChange={(ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                                            //     let key = 'name'
                                                            //     setApproverEditData(setApproverItemData([...approverEditData], item, key, data.value))
                                                            // }}
                                                            ></Input>
                                                        </TableCellLayout>
                                                    </TableCell>
                                                    <TableCell key={'table-cell-type'}>
                                                        <TableCellLayout
                                                            key={'table-cell-layout-type'}
                                                        // {...columnSizing.getTableCellProps('type')} key={'type'}
                                                        >
                                                            <Input
                                                                key={item.adiUUID + '-type'}
                                                                appearance="underline"
                                                                type="text"
                                                                defaultValue={item.server.type}
                                                            // value={item.server.type}
                                                            // onChange={(ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                                            //     let key = 'type'
                                                            //     setApproverEditData(setApproverItemData([...approverEditData], item, key, data.value))
                                                            // }}
                                                            ></Input>
                                                        </TableCellLayout>
                                                    </TableCell>
                                                    <TableCell key={'table-cell-version'}>
                                                        <TableCellLayout
                                                            key={'table-cell-layout-version'}
                                                        // {...columnSizing.getTableCellProps('version')} key={'version'}
                                                        >
                                                            <Input
                                                                key={item.adiUUID + '-version'}
                                                                appearance="underline"
                                                                type="text"
                                                                defaultValue={item.server.version}
                                                            // value={item.server.version}
                                                            // onChange={(ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                                            //     let key = 'version'
                                                            //     setApproverEditData(setApproverItemData([...approverEditData], item, key, data.value))
                                                            // }}
                                                            ></Input>
                                                        </TableCellLayout>
                                                    </TableCell>
                                                </>
                                            ) : (
                                                <TableCell
                                                    key={'table-cell-server-description'}
                                                // {...columnSizing.getTableCellProps('server')} key={'server'}
                                                >

                                                    <TableCellLayout key={'table-cell-layout-server-description'}>
                                                        {buildServerDescription(item)}
                                                    </TableCellLayout>
                                                </TableCell>

                                            )}

                                            {props.editMode ? (
                                                <>
                                                    <TableCell
                                                        key={'table-cell-client-id'}
                                                    // {...columnSizing.getTableCellProps('clientId')} key={'clientId'}
                                                    >
                                                        <TableCellLayout key={'table-cell-layout-client-id'}>
                                                            <Input
                                                                key={item.adiUUID + '-clientId'}
                                                                appearance="underline"
                                                                type="number"
                                                                defaultValue={item.server.client.id.toString()}
                                                            // value={item.server.client.id.toString()}
                                                            // onChange={(ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                                            //     let key = 'clientId'
                                                            //     setApproverEditData(setApproverItemData([...approverEditData], item, key, data.value))
                                                            // }}
                                                            />
                                                        </TableCellLayout>
                                                    </TableCell>
                                                    <TableCell key={'table-cell-client-description'}
                                                    // {...columnSizing.getTableCellProps('clientDescription')} key={'clientDescription'}
                                                    >
                                                        <TableCellLayout key={'table-cell-layout-client-description'}>
                                                            <Input
                                                                key={item.adiUUID + '-clientDescription'}
                                                                appearance="underline"
                                                                type="text"
                                                                defaultValue={item.server.client.description.toString()}
                                                            // value={item.server.client.description.toString()}
                                                            // onChange={(ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                                            //     let key = 'clientDescription'
                                                            //     setApproverEditData(setApproverItemData([...approverEditData], item, key, data.value))
                                                            // }}
                                                            />
                                                        </TableCellLayout>
                                                    </TableCell>
                                                </>
                                            ) : (
                                                <TableCell
                                                    key={'table-cell-client'}
                                                // {...columnSizing.getTableCellProps('clientId')} key={'clientId'}
                                                >
                                                    <TableCellLayout key={'table-cell-layout-client'}>
                                                        {item.server.client.id} - {item.server.client.description}
                                                    </TableCellLayout>
                                                </TableCell>
                                            )}

                                            {props.editMode ? (
                                                <>
                                                    <TableCell
                                                        key={'table-cell-functional-approver'}
                                                    // {...columnSizing.getTableCellProps('functionalApprover')} key={'functionalApprover'}
                                                    >
                                                        <TableCellLayout key={'table-cell-layout-functional-approver'}>
                                                            <EmailCombobox
                                                                key={item.adiUUID + '-functionalApprover'}
                                                                emails={props.emails}
                                                                selectedOptions={[{ name: item.server.approvers.get(ApproverType.Functional)?.approverName, email: item.server.approvers.get(ApproverType.Functional)?.approverEmail }]}
                                                                value={[{ name: item.server.approvers.get(ApproverType.Functional)?.approverName, email: item.server.approvers.get(ApproverType.Functional)?.approverEmail }]}
                                                                placeholder={'Functional Approver'}
                                                                setItemData={(data: Array<string>) => {
                                                                    let key = 'functionalApprover'
                                                                    setApproverData(setApproverItemData([...approverData], item, key, data[0]))
                                                                }}
                                                                appearance="underline"
                                                                multiple={false}
                                                                useTabs={false}
                                                            />
                                                        </TableCellLayout>
                                                    </TableCell>
                                                    <TableCell
                                                        key={'table-cell-technical-approver'}
                                                    // {...columnSizing.getTableCellProps('technicalApprover')} key={'technicalApprover'}
                                                    >
                                                        <TableCellLayout key={'table-cell-layout-technical-approver'}>
                                                            <EmailCombobox
                                                                key={item.adiUUID + '-technicalApprover'}
                                                                emails={props.emails}
                                                                selectedOptions={[{ name: item.server.approvers.get(ApproverType.Technical)?.approverName, email: item.server.approvers.get(ApproverType.Technical)?.approverEmail }]}
                                                                value={[{ name: item.server.approvers.get(ApproverType.Technical)?.approverName, email: item.server.approvers.get(ApproverType.Technical)?.approverEmail }]}
                                                                placeholder={'Technical Approver'}
                                                                setItemData={(data: Array<string>) => {
                                                                    let key = 'technicalApprover'
                                                                    setApproverData(setApproverItemData([...approverData], item, key, data[0]))
                                                                }}
                                                                appearance="underline"
                                                                multiple={false}
                                                                useTabs={false}
                                                            />
                                                        </TableCellLayout>
                                                    </TableCell>
                                                    <TableCell
                                                        key={'table-cell-basis-approver'}
                                                    // {...columnSizing.getTableCellProps('basisApprover')} key={'basisApprover'}
                                                    >
                                                        <TableCellLayout key={'table-cell-layout-basis-approver'}>
                                                            <EmailCombobox
                                                                key={item.adiUUID + '-basisApprover'}
                                                                emails={props.emails}
                                                                selectedOptions={[{ name: item.server.approvers.get(ApproverType.Basis)?.approverName, email: item.server.approvers.get(ApproverType.Basis)?.approverEmail }]}
                                                                value={[{ name: item.server.approvers.get(ApproverType.Basis)?.approverName, email: item.server.approvers.get(ApproverType.Basis)?.approverEmail }]}
                                                                placeholder={'Basis Approver'}
                                                                setItemData={(data: Array<string>) => {
                                                                    let key = 'basisApprover'
                                                                    setApproverData(setApproverItemData([...approverData], item, key, data[0]))
                                                                }}
                                                                appearance="underline"
                                                                multiple={false}
                                                                useTabs={false}
                                                            />
                                                        </TableCellLayout>
                                                    </TableCell>
                                                </>
                                            ) : (
                                                <TableCell
                                                    key={'table-cell-approver'}
                                                // {...columnSizing.getTableCellProps(approverType + 'Approver')} key={approverType + 'Approver'}
                                                >
                                                    <TableCellLayout key={'table-cell-layout-approver'}>
                                                        {
                                                            item.server.approvers.get(approverTypeToEnum(approverType))?.approverName +
                                                            (
                                                                item.server.approvers.get(approverTypeToEnum(approverType))?.approverName !== item.server.approvers.get(approverTypeToEnum(approverType))?.approverEmail
                                                                    ? (' <' + item.server.approvers.get(approverTypeToEnum(approverType))?.approverEmail + '>')
                                                                    : ''
                                                            )
                                                        }
                                                    </TableCellLayout>
                                                </TableCell>

                                            )
                                            }
                                            {props.editMode ? (
                                                <>
                                                    <TableCell
                                                        key={'table-cell-functional-ref-user'}
                                                    // {...columnSizing.getTableCellProps('functionalRefUser')} key={'functionalRefUser'}
                                                    >
                                                        <TableCellLayout key={'table-cell-layout-functional-ref-user'}>
                                                            <Input
                                                                key={item.adiUUID + '-functionalRefUser'}
                                                                type={'text'}
                                                                defaultValue={item.server.approvers.get(ApproverType.Functional)?.referenceUser}
                                                                // value={item.server.approvers.get(ApproverType.Functional)?.referenceUser}
                                                                // onChange={(ev, data) => {
                                                                //     let key = 'functionalRefUser'
                                                                //     setApproverEditData(setApproverItemData([...approverEditData], item, key, data.value))
                                                                // }}
                                                                appearance="underline"
                                                                placeholder='Functional Ref User'
                                                            />
                                                        </TableCellLayout>
                                                    </TableCell>
                                                    <TableCell key={'table-cell-technical-ref-user'}
                                                    // {...columnSizing.getTableCellProps('technicalRefUser')} key={'technicalRefUser'}
                                                    >
                                                        <TableCellLayout key={'table-cell-layout-technical-ref-user'}>
                                                            <Input
                                                                key={item.adiUUID + '-technicalRefUser'}
                                                                type={'text'}
                                                                defaultValue={item.server.approvers.get(ApproverType.Technical)?.referenceUser}
                                                                // value={item.server.approvers.get(ApproverType.Technical)?.referenceUser}
                                                                // onChange={(ev, data) => {
                                                                //     let key = 'technicalRefUser'
                                                                //     setApproverEditData(setApproverItemData([...approverEditData], item, key, data.value))
                                                                // }}
                                                                appearance="underline"
                                                                placeholder='Technical Ref User'
                                                            />
                                                        </TableCellLayout>
                                                    </TableCell>
                                                    <TableCell
                                                        key={'table-cell-basis-ref-user'}
                                                    // {...columnSizing.getTableCellProps('basisRefUser')} key={'basisRefUser'}
                                                    >
                                                        <TableCellLayout key={'table-cell-layout-basis-ref-user'}>
                                                            <Input
                                                                key={item.adiUUID + '-basisRefUser'}
                                                                type={'text'}
                                                                defaultValue={item.server.approvers.get(ApproverType.Basis)?.referenceUser}
                                                                // value={item.server.approvers.get(ApproverType.Basis)?.referenceUser}
                                                                // onChange={(ev, data) => {
                                                                //     let key = 'basisRefUser'
                                                                //     setApproverEditData(setApproverItemData([...approverEditData], item, key, data.value))
                                                                // }}
                                                                appearance="underline"
                                                                placeholder='Basis Ref User'
                                                            />
                                                        </TableCellLayout>
                                                    </TableCell>
                                                </>
                                            ) : null
                                            }
                                            {
                                                props.editMode ? (
                                                    <>
                                                        <TableCell
                                                            key={'table-cell-port'}
                                                        // {...columnSizing.getTableCellProps('port')} key={'port'}
                                                        >
                                                            <TableCellLayout key={'table-cell-layout-port'}>
                                                                <Input
                                                                    key={item.adiUUID + '-port'}
                                                                    type={'number'}
                                                                    defaultValue={item.server.port.toString()}
                                                                    // value={item.server.port.toString()}
                                                                    // onChange={(ev, data) => {
                                                                    //     let key = 'port'
                                                                    //     setApproverEditData(setApproverItemData([...approverEditData], item, key, data.value))
                                                                    // }}
                                                                    appearance="underline"
                                                                    placeholder='Port'
                                                                />
                                                            </TableCellLayout>
                                                        </TableCell>
                                                        <TableCell
                                                            key={'table-cell-address'}
                                                        // {...columnSizing.getTableCellProps('address')} key={'address'}
                                                        >
                                                            <TableCellLayout key={'table-cell-layout-address'}>
                                                                <Input
                                                                    key={item.adiUUID + '-address'}
                                                                    type={'text'}
                                                                    defaultValue={item.server.address}
                                                                    // value={item.server.address}
                                                                    // onChange={(ev, data) => {
                                                                    //     let key = 'address'
                                                                    //     setApproverEditData(setApproverItemData([...approverEditData], item, key, data.value))
                                                                    // }}
                                                                    appearance="underline"
                                                                    placeholder='Address'
                                                                />
                                                            </TableCellLayout>
                                                        </TableCell>
                                                    </>
                                                ) : null
                                            }
                                        </TableRow>)
                                })}
                            </TableBody>
                        </Table>
                    </>)
            }


        </div>
    )

}

export default SystemsTable;