import { Alert } from "@fluentui/react-components/unstable";
import { DismissCircleRegular } from "@fluentui/react-icons";
import { useEffect, useRef } from "react"



const TimedAlert = (props: any) => {

    const timeout = useRef<any>(null);

    useEffect(() => {
        timeout.current = setTimeout(() => {
            props.remove(props.id)
        }, 5000)

        return () => clearTimeout(timeout.current)
    }, [])

    return (<Alert 
                style={{ margin: 'auto', position: 'fixed', top: '0px', right: '0px', marginTop: 1 + (props.index * 3.5) + 'em', width: '300px' }}
                intent={props.intent}
                action={{
                    icon: <DismissCircleRegular />,
                    "aria-label": "dismiss message",
                    onClick: () => {
                        props.remove(props.id)
                    }
                }}
            >{props.message}</Alert>)
}

export default TimedAlert