import { useContext, useState } from "react";
import { TeamsFxContext } from "./Context";
// import WBSAssignRequest from "./wbsassign/WBSAssignRequest";
// import { LeanStaffingAssignment, WBSAssignItem } from "./wbsassign/types";
import { useData } from "@microsoft/teamsfx-react";
import { Spinner } from "@fluentui/react-components";
import SystemsTable from "./SystemsTable";
import axios from "axios";
import { AlertDictionary, AlertType, ApproverDataItem, PouchDBMetaID, SystemAccessRequest } from "../interfaces";
import TimedAlert from './TimedAlert'
import { AccessToken } from "@azure/core-auth";
import { UserInfo } from "@microsoft/teamsfx";

const RequestTab = (props: any) => {

  const [data, setData] = useState<Array<ApproverDataItem>>([]);
  const [requests, setRequests] = useState<Array<any>>([]);
  const [emails, setEmails] = useState<Array<string>>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [alerts, setAlerts] = useState<AlertDictionary>({})


  const { teamsUserCredential } = useContext(TeamsFxContext);

  const graphData = useData<{ userToken: AccessToken | null, userInfo: UserInfo } | undefined>(async () => {
    if (teamsUserCredential) {
      let userToken
      try {
        userToken = await teamsUserCredential.getToken('User.Read');
      } catch (error) {
        await teamsUserCredential.login('User.Read')
        userToken = await teamsUserCredential.getToken('User.Read');
      }
      
      const userInfo = await teamsUserCredential.getUserInfo();
      fetchData(userToken);
      fetchRequests(userToken)
      return { userToken , userInfo };
    }
  })

  const sendAlert = (alert: AlertType) => {
    
    setAlerts(alerts => {
      alerts[alert.id] = alert
      return alerts;
    });

  }

  const fetchRequests = async (userToken: AccessToken | null) => {
    const requests = (await axios.get('/requests', { headers: { Authorization: 'Bearer ' + userToken?.token }})).data as Array<SystemAccessRequest & PouchDBMetaID>
    const timestamped = requests.filter(request => request.timestamp !== undefined).sort((requestA: SystemAccessRequest & PouchDBMetaID, requestB: SystemAccessRequest & PouchDBMetaID) => (new Date(requestB.timestamp).getTime() - new Date(requestA.timestamp).getTime()) > 0 ? 1 : -1)
    setRequests([...timestamped, ...requests.filter(request => request.timestamp === undefined)])
  }


  const fetchData = async (userToken: AccessToken | null) => {
    setLoadingData(true);
    setData(((await axios.get('/systemsList', { headers: { Authorization: 'Bearer ' + userToken?.token }})).data as Array<ApproverDataItem>).filter(entry => entry.Requestable === 'X'))
    setEmails((await axios.get('/emails', { headers: { Authorization: 'Bearer ' + userToken?.token }})).data)
    setLoadingData(false);
  }
  
  return (
    <div style={{width: "100%"}}>
      { Object.keys(alerts).filter(key => alerts[key] !== undefined).length > 0 ? (
        <>
          {Object.keys(alerts).filter(key => alerts[key] !== undefined).map((key, index) => {
            return(
              <TimedAlert 
                index={index}
                key={'alert-'+alerts[key]?.id} 
                remove={(id: string) => {
                  setAlerts(alerts => ({ ...alerts, [id]: undefined }))
                }} 
                id={alerts[key]?.id}  
                intent={alerts[key]?.intent}
                message={alerts[key]?.message}
              >
              </TimedAlert>
            )
          })}
        </>
      ) : null }
      {
        graphData.loading ? (<Spinner />) : graphData.error ? (<h1>{graphData.error.toString()}</h1>) : (
          <div style={{ display: 'grid', gridTemplateColumns: '100%', gap: '2em', alignContent: 'center', textAlign: 'center', alignItems: 'center', width: '100%', flexDirection: 'row' }}>
            {/* <div style={{ display: 'grid', gridTemplateColumns: '100%', gap: '2em', alignContent: 'center', textAlign: 'center', alignItems: 'center', width: '100%', flexDirection: 'row' }}>
              <div style={{ width: '100%', display: 'grid' }}>
                <h1>SAP System Access</h1>
              </div>
              <div style={{ width: '100%', display: 'grid' }}>
                <h2>Logged In As: {graphData?.profile.mail}</h2>
              </div>
            </div> */}
            <div style={{ display: 'grid', gridTemplateColumns: '100%', gap: '2em', alignContent: 'center', textAlign: 'center', alignItems: 'center', width: '100%', flexDirection: 'row' }}>
              { !loadingData ? <SystemsTable data={data} editMode={false} emails={emails} sendAlert={sendAlert} graphData={graphData} fetchRequests={fetchRequests} requests={requests} /> : <Spinner/> }
            </div>
          </div>
        ) 
      }
    </div>
  )
}
export default RequestTab;
